import { About } from "./About";
import { Portada } from "./Portada";
import { Subportada } from "./Subportada";
import { VideoList } from "./VideoList";
import { Contact } from "./Contact";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Portada></Portada>
      <Subportada></Subportada>
      <About></About>
      <VideoList></VideoList>
      <Contact></Contact>
    </div>
  );
}

export default App;
//
