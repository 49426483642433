export function About() {
  return (
    <div className="about">
      <h1>About me</h1>
      <h2>English</h2>
      <p>
        I like to create music as if I was solving a puzzle with pieces that I
        create myself on the fly. I try to find a balance between the music I
        hear in my head and music that surprises me. It is a game, a personal
        therapy and a tool to communicate with other people.
      </p>

      <h2>Català</h2>
      <p>
        M’agrada crear música com si estigués resolent un trencaclosques amb
        peces que jo mateix vaig creant sobre la marxa. Intento trobar un
        equilibri entre la música que escolto al cap i la música que em sorprèn.
        És un joc, una teràpia personal i una eina per comunicar-me amb altres
        persones.
      </p>

      <h2>Español</h2>
      <p>
        Me gusta crear música como si estuviera resolviendo un rompecabezas con
        piezas que yo mismo voy creando sobre la marcha. Intento encontrar un
        equilibrio entre la música que escucho en la cabeza y la música que me
        sorprende. Es un juego, una terapia personal y una herramienta para
        comunicarse con otras personas.
      </p>
    </div>
  );
}
