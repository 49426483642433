import { Video } from "./Video";

export function VideoList() {
  return (
    <div className="VideoList">
      <div className="VideoListgrid">
        <Video vimeoId="395432686" />
        <Video vimeoId="356720108" />
        <Video vimeoId="288711504" />
        <Video vimeoId="382809792" />
        <Video vimeoId="182334750" />
        <Video vimeoId="180819257" />
      </div>
    </div>
  );
}
