export function Video({ vimeoId }: { vimeoId?: string }) {
  return (
    <div className="videoitem">
      {vimeoId ? (
        <iframe
          src={`https://player.vimeo.com/video/${vimeoId}?h=ef103ac0a7&title=0&byline=0&portrait=0`}
          allow="autoplay; fullscreen; picture-in-picture"
        ></iframe>
      ) : (
        <p>No hay video</p>
      )}
    </div>
  );
}
