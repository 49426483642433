import "./Contact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faComment,
  faAddressBook,
} from "@fortawesome/free-solid-svg-icons";

import {
  faInstagram,
  faVimeo,
  faFacebook,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
export function Contact() {
  return (
    <div className="contact">
      <h1>Contact</h1>
      <div className="iconenvelope">
        <img src="/envelope.png" alt="Contact" />
      </div>
      <h2>hola@nicomendezlucas.cat</h2>
      <div className="socialbar">
        <div>
          {" "}
          <a href="https://www.instagram.com/nicomendezlucas/" target="_blank">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </div>
        <div>
          <a href="https://vimeo.com/nicomendezlucas" target="_blank">
            {" "}
            <FontAwesomeIcon icon={faVimeo} />{" "}
          </a>
        </div>
        <div>
          <a
            href="https://www.facebook.com/nicomendezlucasmusic/"
            target="_blank"
          >
            <FontAwesomeIcon icon={faFacebook} />
          </a>
        </div>
        <div>
          <a href="https://twitter.com/nicomendezlucas" target="_blank">
            <FontAwesomeIcon icon={faTwitter} />
          </a>
        </div>
      </div>
    </div>
  );
}
